export const LOADING: 'LOADING' = 'LOADING';
export const LOAD: 'LOAD' = 'LOAD';
export const SET_COEFFICIENT_COUNT: 'SET_COEFFICIENT_COUNT' = 'SET_COEFFICIENT_COUNT';
export const SET_GAME_STATE: 'SET_GAME_STATE' = 'SET_GAME_STATE';
export const SET_CURRENT_BID: 'SET_CURRENT_BID' = 'SET_CURRENT_BID';
export const SET_VALET_COUNT: 'SET_VALET_COUNT' = 'SET_VALET_COUNT';
export const INIT_VALET_COUNT: 'INIT_VALET_COUNT' = 'INIT_VALET_COUNT';
export const FETCH_BET_HISTORY_BY_USER_ID: 'FETCH_BET_HISTORY_BY_USER_ID' = 'FETCH_BET_HISTORY_BY_USER_ID';
export const SET_USER_ID: 'SET_USER_ID' = 'SET_USER_ID';
export const SAVE_BET_BY_USER_ID: 'SAVE_BET_BY_USER_ID' = 'SAVE_BET_BY_USER_ID';
export const SET_STACK_OF_ACTIVE_BET: 'SET_STACK_OF_ACTIVE_BET' = 'SET_STACK_OF_ACTIVE_BET';
export const SET_ERROR_STATE: 'SET_ERROR_STATE' = 'SET_ERROR_STATE';
export const SET_SHAKE_ANIMATION: 'SET_SHAKE_ANIMATION' = 'SET_SHAKE_ANIMATION';
export const SET_GO_TO_WEBSITE_LINK: 'SET_GO_TO_WEBSITE_LINK' = 'SET_GO_TO_WEBSITE_LINK';
const ENDPOINT = 'https://tales.4u.studio';

export type TUser = typeof LOADING & typeof LOAD & typeof SET_COEFFICIENT_COUNT;

export type TUserId = string | number | null;

export type TBetHistoryByUserId = {
    id: string,
    user_id: TUserId,
    coefficient: number,
    bet: number,
    winning_amount: number,
    is_active_bet: boolean | string,
    time: string,
};

export type TBet = {
    user_id: TUserId,
    coefficient: number,
    bet: number,
    winning_amount: number,
    is_active_bet: boolean | string,
}

export type TArrayBetHistoryByUserId = Array<TBetHistoryByUserId>;

export const getUserMoney = (user_id: TUserId) => (dispatch: any) => {
    try {
        fetch( `${ENDPOINT}/api/money_amount/${user_id}`)
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(({ money_amount }: { money_amount: number }) => {
                dispatch({
                    type: INIT_VALET_COUNT,
                    payload: money_amount,
                })
            })
            .catch(e => console.log(e))
    } catch (e) {
        console.log(e)
    }
}

export const fetchUserData = (user_id: TUserId) => (dispatch: any) => {
    // dispatch
}

export const fetchAllBetsByUserId = (user_id: TUserId) => (dispatch: any) => {
    try {
        fetch( `${ENDPOINT}/api/bets/${user_id}`, {
            method: 'GET'
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((res: TArrayBetHistoryByUserId) => {
                let result = res.reverse();
                dispatch({
                    type: FETCH_BET_HISTORY_BY_USER_ID,
                    payload: result,
                })
            })
            .catch(e => console.log(e))
    } catch (e) {
        console.log(e)
    }
}

export const fetchSaveBetByUserId = (bet: TBet) => () => {
    try {
        if (bet.user_id)
            fetch( `${ENDPOINT}/api/bets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=uft-8'
                },
                body: JSON.stringify(bet)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .catch(e => console.log(e))
    } catch (e) {
        console.log(e)
    }
}

export const fetchSetUserMoney = (user_id: TUserId, money_amount: number) => () => {
    try {
        if (money_amount) {
            fetch(`${ENDPOINT}/api/money_amount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=uft-8'
                },
                body: JSON.stringify({user_id, money_amount: String(money_amount.toFixed(2))})
            })
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .catch(e => console.log(e))
        }
    } catch (e) {
        console.log(e)
    }
}

export const fetchGetLinkGoToWebsite = (user_id: TUserId) => (dispatch: any) => {
    try {
        fetch(`${ENDPOINT}/api/link/${user_id}`, {
            method: 'GET'
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(({link}: { link:  string }) => {
                dispatch({
                    type: SET_GO_TO_WEBSITE_LINK,
                    payload: link,
                })
            })
            .catch(e => console.log(e))
    } catch (e) {
        console.log(e)
    }
}

export const setBetCount = (betCount: number) => (dispatch: any) => {
    dispatch({
        type: SET_COEFFICIENT_COUNT,
        payload: betCount,
    })
}