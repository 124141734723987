import React, {useEffect} from 'react';
import styles from './style.module.css';
// import graph from '../../assets/graph.svg';
import graph from '../../assets/graph.png';
import anime from 'animejs';
import jetpack from '../../assets/jetpack.gif';
import fire from '../../assets/fire.gif';
import {DURATION_JETPACK} from "./GameLayout";

function Graph({count, gameOver, duration}) {

    useEffect(() => {
        changeSizes(count);
        changePosition(count);
    }, [count]);

    function changeSizes(count) {
        if (!gameOver) {
            anime({
                targets: '.animeObject',
                duration: Math.min(duration, DURATION_JETPACK),
                scaleX: count.x / 12,
                scaleY: count.y / 12.5,
            });
        }
    }

    function changePosition(count) {
        if (!gameOver) {
            anime({
                targets: '.animateJetPack',
                duration: Math.min(duration, DURATION_JETPACK),
                translateX: count.x / 1.4,
                translateY: -count.y / 1.65,
            })
        } else {
            anime({
                targets: '.animateJetPack',
                duration: Math.min(duration, DURATION_JETPACK),
                translateX: count.x + 1000 / 1.4,
                translateY: -count.y - 600 / 1.65,
            });
        }
    }

    return (
        <div className={`${styles.graph}`}>
            <img src={jetpack} alt="jetpack" className={`${styles.jetPackImage} animateJetPack`}/>
            <div className={`${styles.fireContainer} animateJetPack`}>
                <img src={fire} alt="fire" className={`${styles.fire}`}/>
            </div>
            <img src={graph} className={`${styles.graphImage} animeObject`} alt="graph"/>
        </div>
    );
}

export default Graph;
