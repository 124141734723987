import React, {useEffect, useState} from 'react';
import styles from "./style.module.css";
import {TState} from "../../redux/reducers/user";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import LastBid from "../../components/LastBid/LastBid";
import Die from "../../components/Die/Die";
import {useNavigate} from "react-router-dom";
import {dictionary} from "../../utils/dictionary";

const Bids = () => {
    const {betHistoryByUserId}: TState = useSelector((store: RootState) => store.user);
    const [index, setIndex] = useState(0);
    const [countOfBids, setCountOfBids] = useState(10);
    const navigate = useNavigate();
    // тут нам просто нужно получить список со ставками

    function handleClick() {
        // @ts-ignore
        window.Telegram?.WebApp?.HapticFeedback.selectionChanged();
    }

    function getBetTime(date: string) {
        return new Date(date).toLocaleTimeString().slice(0,-3);
    }

    function getBetDate(date: string) {
        return new Date(date).toLocaleDateString().replaceAll('/', '.');
    }

    function handleGoBack() {
        navigate(-1);
    }

    useEffect(() => {
        const App = document.querySelector('.App');
        if (App) {
            setCountOfBids(Math.floor((App.clientHeight - 122) / 48));
        }
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.bidsHeader}>
                <div className={styles.title}>
                    <p>{dictionary('betHistory')}</p>
                </div>
                <div className={styles.closeButton} onClick={() => {
                    handleClick();
                    handleGoBack();
                }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.46455 1.05025C2.07402 0.659728 1.44086 0.659729 1.05033 1.05025C0.659809 1.44078 0.659808 2.07394 1.05033 2.46447L4.58589 6.00002L1.05035 9.53556C0.659829 9.92608 0.659829 10.5592 1.05035 10.9498C1.44088 11.3403 2.07404 11.3403 2.46457 10.9498L6.0001 7.41424L9.53562 10.9498C9.92614 11.3403 10.5593 11.3403 10.9498 10.9498C11.3404 10.5592 11.3404 9.92606 10.9498 9.53554L7.41432 6.00002L10.9499 2.46449C11.3404 2.07396 11.3404 1.4408 10.9499 1.05027C10.5593 0.659749 9.92616 0.65975 9.53564 1.05027L6.0001 4.58581L2.46455 1.05025Z" fill="#DFE5F2" fill-opacity="0.5"/>
                    </svg>
                </div>
            </div>
            <div className={styles.bidsList} id={'bidsList'}>
                {betHistoryByUserId.slice(index, index + countOfBids).map((item, index) => (
                    <LastBid
                        key={item.id + String(index)}
                        time={getBetTime(item.time)}
                        cash={item.bet}
                        winning_amount={item.winning_amount}
                        coefficient={item.coefficient}
                        date={getBetDate(item.time)}
                        active={item.is_active_bet}
                    />
                ))}
            </div>
            <Die setIndex={setIndex} index={index} countOfBets={countOfBids} page_count={betHistoryByUserId.length}/>
        </div>
    );
}

export default Bids;