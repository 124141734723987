import React, {useEffect} from 'react';
import styles from './header.module.css';

import Wallet from '../../assets/wallet.svg';
import Jet_img from '../../assets/LuckyJet.svg';
import {TState} from "../../redux/reducers/user";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {fetchAllBetsByUserId, TBetHistoryByUserId} from "../../redux/actions/user";
import {GAME_STATE} from "../GameLayout/GameLayout";
import {Link} from "react-router-dom";
import {getCurrentLang, getCurrentUserId} from "../../utils/getCurrentUserId";
import {dictionary} from "../../utils/dictionary";
import {currency} from "../../App";

function getBetHistoryContentItemColor(bet: number) {
    if (bet <= 2) {
        return styles.betHistoryContentItemSmall
    } else if (bet <= 10) {
        return styles.betHistoryContentItemMedium
    } else {
        return styles.betHistoryContentItemHight
    }
}

const Header = () => {
    const dispatch = useDispatch();
    const {coefficientRecord, valet, betHistoryByUserId, gameState}: TState = useSelector((store: RootState) => store.user);

    useEffect(() => {
        if (gameState === GAME_STATE.LOADING) {
            // @ts-ignore
            dispatch(fetchAllBetsByUserId(getCurrentUserId()));
        }
    }, [gameState, dispatch]);

    function handleClick() {
        // @ts-ignore
        window.Telegram?.WebApp?.HapticFeedback.selectionChanged();
    }

    function handleGetLinkWithParams(link: string) {
        let user_id = getCurrentUserId();
        let lang = getCurrentLang();
        link += `?user_id=${user_id}`;
        link += lang ? `&lang=${lang}` : '';
        return link;
    }

    return (
        <div className={styles.header}>
            <div className={styles.userInfo}>
                <img src={Jet_img} alt="Logo" onClick={handleClick}/>

                <div className={styles.cash_record}>
                    <Link to={handleGetLinkWithParams('/bids')} onClick={handleClick}>
                        <div className={styles.cash}>
                            <img src={Wallet} alt="wallet" />
                            <h3>{Math.round((valet ?? 0) * 100) / 100}{currency}</h3>
                        </div>
                    </Link>
                    <div className={styles.line}></div>

                    <div className={styles.Text}>{dictionary('record')}</div>
                    <div className={styles.max_ratio} onClick={handleClick}>
                        <div className={styles.ratio}>
                            {coefficientRecord >= 0 ? coefficientRecord : 0}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.betHistory} onClick={handleClick}>
                <div className={styles.betHistoryContent}>
                    {
                        betHistoryByUserId.filter(item => item.coefficient !== 0).slice(0,7).map((item: TBetHistoryByUserId) => (
                            <div
                                key={item.id}
                                className={`${styles.betHistoryContentItem} ${getBetHistoryContentItemColor(item.coefficient)}`}
                            >
                                <p>{item.coefficient}x</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Header;