import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styles from './style.module.css';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {TState} from "../../redux/reducers/user";
import {
    fetchSaveBetByUserId,
    fetchSetUserMoney,
    SET_COEFFICIENT_COUNT,
    SET_CURRENT_BID, SET_ERROR_STATE,
    SET_VALET_COUNT
} from "../../redux/actions/user";
import {GAME_STATE, GAME_ENDING_INTERVAL} from "../GameLayout/GameLayout";
import {dictionary} from "../../utils/dictionary";
import {currency} from "../../App";

const bidEquals = [50, 100, 250, 'max'];

function handleRoundNumber(value: number) {
    return Math.round(value * 100) / 100;
}

let intervalBringOutMoney: ReturnType<typeof setInterval>;

export function BidsButton() {
    const dispatch = useDispatch();

    const timerRef = useRef<any>(null);
    const {gameState, coefficient, currentBid, valet, user_id, errorState}: TState = useSelector((store: RootState) => store.user);
    const [actualBet, setActualBet] = useState('10');
    const [isBetPlaced, setBetPlaced] = useState(false);
    const inputActualBet = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (gameState === GAME_STATE.GAME_OVER) {
            const winning_amount = handleRoundNumber(currentBid * (coefficient ?? 0));

            setTimeout(() => {
                dispatch({
                    type: SET_CURRENT_BID,
                    payload: 0,
                });
                handleSetBet(0);
                setActualBet('10');
                if (inputActualBet && inputActualBet.current) {
                    inputActualBet.current.value = '10';
                }
                setBetPlaced(false);
            }, GAME_ENDING_INTERVAL);

            setTimeout(() => {
                const lastCoefficient = Number(
                    document.querySelector('.animeBetCount')
                        ?.innerHTML
                        .replace(',', '.')
                        .slice(1)
                );

                // @ts-ignore
                dispatch(fetchSaveBetByUserId({
                    user_id,
                    coefficient: lastCoefficient,
                    bet: currentBid,
                    winning_amount,
                    is_active_bet: isBetPlaced,
                }));
            }, 500);
        }

        if (gameState === GAME_STATE.PLAYING && isBetPlaced && valet) {
            dispatch({
                type: SET_VALET_COUNT,
                payload: -Number(actualBet),
            });

            // @ts-ignore
            dispatch(fetchSetUserMoney(user_id, valet -actualBet));
        }
    }, [gameState]);

    function handleClick() {
        // @ts-ignore
        window.Telegram?.WebApp?.HapticFeedback.selectionChanged();
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;

        if (Number(value) && Number(value) > 0) {
            setActualBet(value);
            if (inputActualBet && inputActualBet.current) {
                inputActualBet.current.value = value.replace('.00', '');
            }
        }
    }

    function handleIntervalBet(count: number, ) {
        let countValue = 0;
        timerRef.current = setInterval(() => {
            handleClick();
            countValue += count;
            handleUpBet(countValue);
        }, 50);
    }

    function handleClearIntervalBet() {
        clearInterval(timerRef.current);
    }

    function handleUpBet(count: number | string) {
        const result = count === 'max' ? valet ?? 0 : +actualBet + +count;
        handleClick();

        if (valet !== null && result >= 0 && gameState === GAME_STATE.LOADING) {
            setActualBet(String(result.toFixed(2)));
            handleSetBet(result);
            if (inputActualBet && inputActualBet.current) {
                inputActualBet.current.value = String(result).replace('.00', '');
            }
        }
    }

    function CancelButton() {
        return (
            <div
                onClick={() => {
                    handleClick();
                    handleCancelBet();
                }}
                className={styles.cancelButton}>
                <p>{dictionary('cancel')}</p>
            </div>
        )
    }

    function BringOutButton({active}: {active: boolean}) {
        const [bringOutCoefficient, setBringOutCoefficient] = useState(0);

        useEffect(() => {
            if (gameState === GAME_STATE.PLAYING && !coefficient) {
                intervalBringOutMoney = setInterval(() => {
                    const actualCoefficient = Number(
                        document.querySelector('.animeBetCount')
                            ?.innerHTML
                            .replace(',', '.')
                            .slice(1)
                    );
                    setBringOutCoefficient(actualCoefficient);
                }, 10);
            } else {
                clearInterval(intervalBringOutMoney);
            }
        }, [gameState]);

        return (
            <div
                onClick={() => {
                    handleBringOutMoney();
                    handleClick();
                }}
                className={`${styles.bringOutButton} ${!active && styles.disabled}`}>
                <p>{handleRoundNumber(currentBid * (coefficient ?? bringOutCoefficient))}{currency}</p>
                <p>{dictionary('bringOut')}</p>
            </div>
        )
    }

    function PutBetButton({active}: {active: boolean}) {
        return (
            <div className={`${styles.putBetButton} ${!active && styles.disabled}`}
                 onClick={() => {
                     handleClick();
                     handleGetBet();
                 }}>
                <p>{dictionary('bet')}</p>
            </div>
        )
    }

    function handleCancelBet() {
        setBetPlaced(false);
    }

    function handleGetBet() {
        if (gameState === GAME_STATE.LOADING && Number(actualBet) <= (valet ?? 0) && Number(actualBet) > 0) {
            setBetPlaced(true);
            dispatch({
                type: SET_CURRENT_BID,
                payload: Number(actualBet),
            });
        } else if (!errorState && gameState === GAME_STATE.LOADING) {
            dispatch({
                type: SET_ERROR_STATE,
                payload: true,
            });
            // @ts-ignore
            window.Telegram?.WebApp?.HapticFeedback.notificationOccurred('warning');
            setTimeout(() => {
                dispatch({
                    type: SET_ERROR_STATE,
                    payload: false,
                });
            }, 2000);
        }
    }

    function handleSetBet(count?: number) {
        dispatch({
            type: SET_CURRENT_BID,
            payload: count ?? Number(actualBet),
        });
    }

    // функция при нажатии на кнопку "Вывести деньги"
    function handleBringOutMoney() {
        if (!coefficient && gameState === GAME_STATE.PLAYING) {
            const actualCoefficient = Number(
                document.querySelector('.animeBetCount')
                    ?.innerHTML
                    .replace(',', '.')
                    .slice(1)
                );
            const actualBetCount = handleRoundNumber(currentBid * (actualCoefficient ?? 0));

            setActualBet('10');
            if (inputActualBet && inputActualBet.current) {
                inputActualBet.current.value = '10';
            }

            dispatch({
                type: SET_VALET_COUNT,
                payload: actualBetCount,
            });

            dispatch({
                type: SET_COEFFICIENT_COUNT,
                payload: actualCoefficient,
            });

            if (valet !== null) {
                // @ts-ignore
                dispatch(fetchSetUserMoney(user_id, valet + actualBetCount));
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.betInput} ${gameState !== GAME_STATE.LOADING && styles.disabled}`}>
                <div className={styles.counterContainer}>
                    <div
                        onMouseDown={() => handleIntervalBet(-10)}
                        onMouseUp={() => handleClearIntervalBet()}
                        onTouchStart={() => handleIntervalBet(-10)}
                        onTouchEnd={() => handleClearIntervalBet()}
                        className={`${styles.counterButton} ${styles.minus}`} />
                    <p>
                        <input
                            ref={inputActualBet}
                            className={styles.input}
                            type="text"
                            value={actualBet}
                            onChange={handleInputChange}
                        />
                        {currency}
                    </p>
                    <div
                        onMouseDown={() => handleIntervalBet(10)}
                        onMouseUp={() => handleClearIntervalBet()}
                        onTouchStart={() => handleIntervalBet(10)}
                        onTouchEnd={() => handleClearIntervalBet()}
                        className={`${styles.counterButton} ${styles.plus}`} />
                </div>
                <div className={styles.line}></div>
                <div className={styles.addingBidsContainer}>
                    {bidEquals.map(bid => (
                        <div
                            className={styles.bid}
                            key={String(Symbol(bid))}
                            onClick={() => handleUpBet(bid)}
                        >
                            <p>{bid === 'max' ? bid : '+' + bid}</p>
                        </div>
                    ))}
                </div>
            </div>

            {!isBetPlaced && <PutBetButton active={gameState === GAME_STATE.LOADING} />}
            {isBetPlaced && gameState === GAME_STATE.LOADING && <CancelButton />}
            {isBetPlaced && gameState !== GAME_STATE.LOADING && <BringOutButton active={!coefficient} />}
        </div>
    );
}
