import React from 'react';
import styles from './style.module.css';
import insideCircle from '../../assets/loader-circle-inside.svg';
import outsideCircle from '../../assets/loader-circle-outside.svg';
import rocket from '../../assets/loader-rocket.svg';
import fires from '../../assets/loader-fiers.svg';
import {dictionary} from "../../utils/dictionary";

export function Loader() {
    return (
        <div className={styles.container}>
            <div className={styles.loaderContainer}>
                <div className={styles.circleContainer}>
                    <div className={styles.circle}>
                        <img className={styles.insideCircle} src={insideCircle} alt="insideCircle" />
                        <img className={styles.outsideCircle} src={outsideCircle} alt="outsideCircle" />
                    </div>
                </div>
                <div className={styles.rocketContainer}>
                    <img className={styles.rocket} src={rocket} alt="rocket" />
                    <img className={styles.fires} src={fires} alt="fires" />
                </div>
            </div>
            <div className={styles.title}>{dictionary('waitingNextRound')}</div>
            <div className={styles.line}></div>
        </div>
    );
}
