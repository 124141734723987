import {EN, getCurrentLang} from "./getCurrentUserId";
export type TLangs = 'en' | 'pt' | 'ru';
type TDICT = {
    [lang: string]: {
        [key: string]: string
    }
}

const dict: TDICT = {
    'en': {
        betHistory: 'MY BET HISTORY',
        record: 'Record',
        bringOut: 'BRING OUT',
        waitingNextRound: `Waiting for \n the next round`,
        managedToPickUp: 'You managed to pick up!',
        goToWebsite: 'GO TO THE WEBSITE',
        flewAway: 'FLEW AWAY',
        cancel: 'CANCEL',
        bet: 'BET',
        noFundsInAccount: 'There are not enough funds in the account',
    },
    'es': {
        betHistory: 'MI HISTORIAL DE APUESTA',
        record: 'Registro',
        bringOut: 'RETIRAR',
        waitingNextRound: `Esperando \n la siguiente ronda`,
        managedToPickUp: '¡Lograste recoger!',
        goToWebsite: 'IR AL SITIO WEB',
        flewAway: 'FUE VOLANDO',
        cancel: 'CANCELAR',
        bet: 'APUESTA',
        noFundsInAccount: 'No hay fondos suficientes en la cuenta',
    },
    'fr': {
        betHistory: 'MON HISTORIQUE DE PARI',
        record: 'Enregistrer',
        bringOut: 'RETIRER',
        waitingNextRound: `Attendre \n le prochain tour`,
        managedToPickUp: 'Vous avez réussi à décrocher !',
        goToWebsite: 'ALLER SUR LE SITE WEB',
        flewAway: "S'EST ENVOLÉE",
        cancel: 'ANNULER',
        bet: 'PARI',
        noFundsInAccount: "Il n'y a pas assez de fonds sur le compte",
    },
    'AZ': {
        betHistory: 'MƏNİM MƏRİC TARİXİ',
        record: 'Qeyd',
        bringOut: 'ÇƏKİL',
        waitingNextRound: `Gözlənti \n növbəti tur`,
        managedToPickUp: 'Siz geri çəkilməyi bacardınız!',
        goToWebsite: 'SAYFAYA KEÇİN',
        flewAway: "UÇDU",
        cancel: 'LƏĞV EDİN',
        bet: 'СТАВКА',
        noFundsInAccount: "Hesabda kifayət qədər vəsait yoxdur",
    },
    'pt': {
        betHistory: 'MEU HISTÓRICO DE APOSTAS',
        record: 'Recorde',
        bringOut: 'TIRAR',
        waitingNextRound: `Aguardando \n a próxima rodada`,
        managedToPickUp: 'Você conseguiu pegar!',
        goToWebsite: 'IR PARA O SITE',
        flewAway: 'VOAR',
        cancel: 'ANULAR',
        bet: 'APOSTAS',
        noFundsInAccount: 'Não há fundos suficientes na conta',
    },
    'ru': {
        betHistory: 'МОЯ ИСТОРИЯ СТАВОК',
        record: 'Рекорд',
        bringOut: 'ВЫВЕСТИ',
        waitingNextRound: `Ожидание \n следующего раунда`,
        managedToPickUp: 'Вы успели вывести!',
        goToWebsite: 'ПЕРЕЙТИ НА САЙТ',
        flewAway: 'УЛЕТЕЛ',
        cancel: 'ОТМЕНА',
        bet: 'СТАВКА',
        noFundsInAccount: 'На счете недостаточно средств',
    }
}

export function dictionary(key: string) {
    const lang = getCurrentLang() as TLangs ?? EN;

    if (lang && dict[lang] && dict[lang][key]){
        return dict[lang][key];
    } else {
        return null;
    }
}