import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.css';
import Graph from "./Graph";
import {Loader} from "../Loader";
import BetCount from "./BetCount";
import {randomResult} from "../../utils/random-bet";
import Managed from "../Managed/Managed";
import {SET_COEFFICIENT_COUNT, SET_GAME_STATE} from "../../redux/actions/user";
import {useDispatch, useSelector} from "react-redux";
import {TState} from "../../redux/reducers/user";
import {RootState} from "../../redux";

type TInitialState = {
    x: number,
    y: number,
    containerWidth: number,
    containerHeight: number,
}

type TGameCycle = {
    gameState: string,
    setGameState: (state: string) => void,
    setGameInterval: (interval: number) => void,
    setCoefficient: (coefficient: number) => void,
    setGameDuration: (duration: number) => void,
}

export const DURATION_JETPACK = 6000;
export const DURATION_BET = 40000;
export const GAME_LOADING_INTERVAL = 8000;
export const GAME_ENDING_INTERVAL = 4000;
const containerSize = 10;
const ONE_SECOND = 1000;
const START_GAME_DURATION = 1000;
const START_BET_COUNT = 1;
const JETPACK_FLY_INTERVAL = 2000;
let jetPackFlyInterval: ReturnType<typeof setInterval>;

export const GAME_STATE = {
    LOADING: 'LOADING',
    PLAYING: 'PLAYING',
    GAME_OVER: 'GAME_OVER',
}

const initialState: TInitialState = {
    x: containerSize,
    y: containerSize,
    containerWidth: containerSize,
    containerHeight: containerSize,
};

function getRandomDuration() {
    return Math.random() / 3 + Math.abs(Math.random() * 3) + 1;
}

function setValueXToAnimation(container: HTMLDivElement | null) {
    if (container)
        return (container.offsetWidth - 120) * (1 + Math.abs(Math.random() / 10));
    return containerSize;
}

function setValueYToAnimation(container: HTMLDivElement | null) {
    if (container)
        return (container.offsetHeight - 80) * (1 + 3 * Math.abs(Math.random() / 10));
    return containerSize;
}

function Background() {
    const {gameState}: TState = useSelector((store: RootState) => store.user);

    return (
        <>
            <div className={`${styles.backgroundStars} ${styles.background}`}></div>
            <div className={`${gameState === GAME_STATE.PLAYING && styles.runningY} ${styles.backgroundLeft} ${styles.background}`}></div>
            <div className={`${gameState === GAME_STATE.PLAYING && styles.runningX} ${styles.backgroundBottom} ${styles.background}`}></div>
            <div className={` ${styles.skyes}`}></div>
        </>
    )
}

function getGameCycle({gameState, setGameState, setGameInterval, setCoefficient, setGameDuration} : TGameCycle) {
    if (gameState === GAME_STATE.LOADING) {
        const coefficient = randomResult();
        const duration = coefficient * ONE_SECOND * getRandomDuration();
        const interval = Math.min(duration, DURATION_BET);

        setCoefficient(coefficient);
        setGameDuration(duration);
        setGameState(GAME_STATE.PLAYING);
        setGameInterval(interval);

        return interval;
    } else if (gameState === GAME_STATE.PLAYING) {
        setGameState(GAME_STATE.GAME_OVER);
        setGameInterval(GAME_ENDING_INTERVAL);

        return GAME_ENDING_INTERVAL;
    } else {
        setGameState(GAME_STATE.LOADING);
        setGameInterval(GAME_LOADING_INTERVAL);

        return GAME_LOADING_INTERVAL;
    }
}

export function GameLayout() {
    const dispatch = useDispatch();
    const {coefficient: coefficientState, errorState}: TState = useSelector((store: RootState) => store.user);

    const [countState, setCount] = useState({
        ...initialState,
        x: 150,
        y: 150,
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const [gameState, setGameState] = useState(GAME_STATE.LOADING);
    const [interval, setGameInterval] = useState(GAME_LOADING_INTERVAL);
    const [coefficient, setCoefficient] = useState(START_BET_COUNT);
    const [duration, setGameDuration] = useState(START_GAME_DURATION);

    function handleJetPackFlyAnimation() {
        setCount({
            ...countState,
            x: setValueXToAnimation(containerRef.current),
            y: setValueYToAnimation(containerRef.current),
        });
    }

    // Следим за значением актуального коэффициента
    useEffect(() => {
        if (coefficientState) {
            setCoefficient(coefficientState);
        }
    }, [coefficientState]);

    // Устанавливаем игровой тайминг / закидываем в стор состояние игры / при новой игре сбрасываем коэффициент
    useEffect(() => {
        if (gameState === GAME_STATE.PLAYING) {
            jetPackFlyInterval = setInterval(handleJetPackFlyAnimation, JETPACK_FLY_INTERVAL);
        } else {
            clearInterval(jetPackFlyInterval);
        }

        dispatch({
            type: SET_GAME_STATE,
            payload: gameState,
        });

        if (gameState === GAME_STATE.GAME_OVER) {
            // @ts-ignore
            window.Telegram?.WebApp?.HapticFeedback.notificationOccurred('error');
        }

        if (gameState === GAME_STATE.LOADING) {
            dispatch({
                type: SET_COEFFICIENT_COUNT,
                payload: null,
            });
        }
    }, [gameState, dispatch])

    // Устанавливаем координаты пользователя
    // Устанавливаем основной игровой тайминг - запускаем игровой цикл
    useEffect(() => {
        setCount({
            containerHeight: containerRef.current?.offsetHeight ?? containerSize,
            containerWidth: containerRef.current?.offsetWidth ?? containerSize,
            x: setValueXToAnimation(containerRef.current),
            y: setValueYToAnimation(containerRef.current),
        });

        const mainGameInterval = setTimeout(() => {
            if (gameState === GAME_STATE.PLAYING) {
                setCount({
                    ...countState,
                    x: setValueXToAnimation(containerRef.current),
                    y: setValueYToAnimation(containerRef.current),
                });
            }

            getGameCycle({
                gameState,
                setGameState,
                setGameInterval,
                setCoefficient,
                setGameDuration
            });
            clearTimeout(mainGameInterval);
        }, interval);
    }, [interval]);

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.border}>
                {gameState === GAME_STATE.LOADING ? (
                    <Loader/>
                ) : (
                    <>
                        <BetCount coefficient={coefficient} duration={duration}/>
                        <Graph count={countState} gameOver={gameState === GAME_STATE.GAME_OVER} duration={duration}/>
                    </>
                )}
                <Background />
                {coefficientState && <Managed errorState={errorState}/>}
                {errorState && <Managed errorState={errorState}/>}
            </div>
        </div>
    );
}
