import React from 'react';
import styles from './die.module.css';
import arrow from '../../assets/arrow.svg';
import arrow_right from '../../assets/arrow_right.svg';

function Die({setIndex, index, countOfBets, page_count}: {
    setIndex: (newIndex: number) => void,
    index: number,
    countOfBets: number,
    page_count: number,
}) {

    function handleCheckNextIndex(nextIndex: number) {
        console.log('### nextIndex: ', nextIndex)
        console.log('### Math.floor(page_count / countOfBets): ',Math.floor(page_count / countOfBets))
        if (nextIndex >= 0 && nextIndex / countOfBets <= Math.floor(page_count / countOfBets))
            setIndex(nextIndex);
    }

  return (
    <div className={styles.die}>
      <div className={styles.darken}>
          {Math.floor((index-1) / countOfBets) >= 0 ? Math.floor((index-1) / countOfBets) : '  '}
      </div>
      <div onClick={() => handleCheckNextIndex(index - countOfBets)}>
        <img src={arrow_right} alt="right arrow" />
      </div>
      <div className={styles.text}>
          {Math.floor((index) / countOfBets)}
      </div>
      <div className={styles.Image} onClick={() => handleCheckNextIndex(index + countOfBets)}>
        <img src={arrow} alt="left arrow" />
      </div>
      <div className={styles.darken}>
          {index / countOfBets < Math.floor(page_count / countOfBets) ? Math.floor(page_count / countOfBets) : '  '}
      </div>
    </div>
  );
}

export default Die;
