import React from 'react';
import styles from './style.module.css';
import {TState} from "../../redux/reducers/user";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import 'animate.css';
import {dictionary} from "../../utils/dictionary";
import {Link} from "react-router-dom";

export function GoToWebButton() {
    const {shake, goToWebsiteLink}: TState = useSelector((store: RootState) => store.user);

    return (
        <a href={goToWebsiteLink.includes('https') ? goToWebsiteLink : 'https://' + goToWebsiteLink}>
            <div className={`${styles.container} animate__animated  ${shake && 'null_animate__shakeY'}`}>
                <p>{dictionary('goToWebsite')}</p>
            </div>
        </a>
    );
}
