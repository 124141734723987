export function randomResult() {
    let randomNumber = Math.random();
    if (randomNumber <= 0.2) {
        return Math.random() * (2 - 1 + 1) + 1;
    } else if (randomNumber > 0.2 && randomNumber <= 0.7) {
        return Math.random() * (4 - 2 + 1) + 2;
    } else if (randomNumber > 0.7 && randomNumber <= 0.9) {
        return Math.random() * (20 - 4 + 1) + 4;
    } else {
        return Math.random() * (300 - 20 + 1) + 20;
    }
}