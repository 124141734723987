import React from 'react';
import styles from './Lastbid.module.css';
import {currency} from "../../App";

function getBetHistoryContentItemColor(bet: number) {
    if (bet <= 2) {
        return styles.betHistoryContentItemSmall
    } else if (bet <= 10) {
        return styles.betHistoryContentItemMedium
    } else {
        return styles.betHistoryContentItemHight
    }
}

const LastBid = ({time, date, cash, coefficient, winning_amount, active}: any) => {
    return (
        <div className={`${styles.container} ${active ? styles.active : styles.disable}`}>
            <div className={styles.dateTime}>
                {time} <br /> 
                {date}
            </div>

            <div className={styles.moneyRatio}>
                <div className={styles.cash}>
                    {cash}{currency}
                </div>

                <div className={`${styles.ratio} ${getBetHistoryContentItemColor(coefficient)}`}>
                    {coefficient}x
                </div>
            </div>

            <div className={styles.win}>
                {winning_amount}{currency}
            </div>
        </div>
    );
}
 
export default LastBid;