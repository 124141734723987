export const USER_ID = 'user_id';
export const LANG = 'lang';
export const EN = 'en'

export function getCurrentUserId() {
    try {
        const url = new URL(window.location.href);

        return url.searchParams.get(USER_ID);
    } catch (e) {
        console.error(`getCurrentUserId - ${e}`);
    }
}

export function getCurrentLang(){
    try {
        const url = new URL(window.location.href);

        return url.searchParams.get(LANG);
    } catch (e) {
        console.error(`getCurrentLang - ${e}`);
    }
}
