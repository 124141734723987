import {
    FETCH_BET_HISTORY_BY_USER_ID,
    INIT_VALET_COUNT,
    LOAD,
    LOADING,
    SAVE_BET_BY_USER_ID,
    SET_COEFFICIENT_COUNT,
    SET_CURRENT_BID,
    SET_GAME_STATE,
    SET_USER_ID,
    SET_VALET_COUNT,
    SET_STACK_OF_ACTIVE_BET,
    SET_ERROR_STATE,
    SET_SHAKE_ANIMATION,
    type TBet, SET_GO_TO_WEBSITE_LINK,
} from "../actions/user";

import type {TUser, TUserId, TArrayBetHistoryByUserId} from '../actions/user';

const initialState = {
    user_id: '',
    isLoading: false,
    coefficient: null,
    winningAmount: 0,
    currentBid: 0,
    gameState: 'LOADING',
    valet: null,
    betHistoryByUserId: [],
    stackOfActiveBet: [],
    coefficientRecord: 0,
    errorState: false,
    shake: false,
    goToWebsiteLink: '',
}

export type TState = {
    user_id: TUserId,
    isLoading: boolean
    coefficient: number | null,
    winningAmount: number,
    currentBid: number,
    gameState: string,
    valet: number | null,
    betHistoryByUserId: TArrayBetHistoryByUserId
    stackOfActiveBet: Array<{placedBets: number}>,
    coefficientRecord: number,
    errorState: boolean,
    shake: boolean,
    goToWebsiteLink: string,
}

type TActions = TUser;

export const userReducer = (state: TState = initialState, action: { type: TActions, payload?: any }) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                isLoading: true
            }
        case SET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            }
        case LOAD:
            return {
                ...state,
                isLoading: false
            }
        case SET_COEFFICIENT_COUNT:
            return {
                ...state,
                coefficient: action.payload,
            }
        case SET_SHAKE_ANIMATION:
            return {
                ...state,
                shake: action.payload,
            }
        case FETCH_BET_HISTORY_BY_USER_ID:
            return {
                ...state,
                betHistoryByUserId: action.payload,
                coefficientRecord: Math.max(
                    ...action.payload
                        .filter((item: TBet) => item.is_active_bet && Number((item.winning_amount / item.bet).toFixed(2)))
                        .map((item: any) => item = Number((item.winning_amount / item.bet).toFixed(2)))
                    ) ?? 0,
            }
        case SET_GAME_STATE:
            return {
                ...state,
                gameState: action.payload
            }
        case SET_CURRENT_BID:
            return {
                ...state,
                currentBid: action.payload,
            }
        case SET_STACK_OF_ACTIVE_BET:
            return {
                ...state,
                stackOfActiveBet: action.payload,
            }
        case SET_VALET_COUNT:
            let valetValue = (state.valet + action.payload).toFixed(2);
            return {
                ...state,
                valet: Number(valetValue),
            }
        case INIT_VALET_COUNT:
            return {
                ...state,
                valet: Number(action.payload.toFixed(2)),
            }
        case SET_ERROR_STATE:
            return {
                ...state,
                errorState: action.payload,
                shake: action.payload,
            }
        case SET_GO_TO_WEBSITE_LINK:
            return {
                ...state,
                goToWebsiteLink: action.payload,
            }
        case SAVE_BET_BY_USER_ID:
            return {
                ...state,
            }
        default:
            return state;
    }
}