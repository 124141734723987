import React from 'react';
import Die from "../../components/Die/Die";
import styles from './style.module.css';
import {GameLayout} from "../../components/GameLayout";
import Header from '../../components/Header/Header';
import Managed from '../../components/Managed/Managed'
import LastBid from '../../components/LastBid/LastBid';
import {BidsButton} from "../../components/BidsButton";
import {GoToWebButton} from '../../components/GoToWebButton';

function Main() {
    return (
        <div className={styles.container}>
            <Header />
            <GameLayout></GameLayout>
            <BidsButton />
            <GoToWebButton />
        </div>
    );
}

export default Main;
