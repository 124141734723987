import React from 'react';
import styles from './managed.module.css';
import {TState} from "../../redux/reducers/user";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import {dictionary} from "../../utils/dictionary";
import {currency} from "../../App";

const Manaegd = ({errorState}: { errorState: boolean }) => {
    const {coefficient, currentBid}: TState = useSelector((store: RootState) => store.user);

    return (
        <div className={styles.container}>
            {!errorState ? (
                <>
                    <div className={styles.text}>
                        <p>{dictionary('managedToPickUp')}</p>
                        <h2>{Math.round(currentBid * (coefficient ?? 0) * 100) / 100}{currency}</h2>
                    </div>

                    <div className={styles.ratio}>
                        <p>{coefficient}X</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.errorText}>
                        <h2>{dictionary('noFundsInAccount')}</h2>
                    </div>
                </>
                )
            }
        </div>
    );
}
 
export default Manaegd;