
import React, {useEffect} from 'react';
import styles from './style.module.css';
import anime from 'animejs';
import {DURATION_BET} from './GameLayout';

function handleNormalizeCoefficient(coefficient) {
    let coefficientValue = Number(coefficient);

    if (coefficient.includes('.') && Math.round(coefficientValue * 100) % 10 === 0) {
        coefficientValue = 'x' + coefficient.replace('.', ',') + '0';
    } else if (!coefficient.includes('.')) {
        coefficientValue = 'x' + coefficient + ',00';
    } else {
        coefficientValue = 'x' + coefficient.replace('.', ',');
    }
    return coefficientValue;
}

function BetCount({coefficient, duration}) {
    let elem;

    useEffect(() => {
        elem = document.querySelector('.animeBetCount');
        changeBet(coefficient);
    }, []);

    function changeBet(count) {
        anime({
            targets: '.animeBetCount',
            innerHTML: [1, count],
            easing: 'linear',
            duration: Math.min(duration, DURATION_BET),
            round: 100,
            update: function() {
                elem.innerHTML = handleNormalizeCoefficient(elem.innerHTML);
            }
        });
    }

    return (
        <div className={`${styles.betCount}`}>
            <p className={'animeBetCount'}></p>
        </div>
    );
}

export default BetCount;
