import React, {useEffect} from 'react';
import Main from './pages/Main/Main';
import {fetchAllBetsByUserId, fetchGetLinkGoToWebsite, getUserMoney, SET_USER_ID} from "./redux/actions/user";
import {useDispatch, useSelector} from "react-redux";
import Bids from "./pages/Bids/Bids";
import {getCurrentUserId} from "./utils/getCurrentUserId";
import {TState} from "./redux/reducers/user";
import {RootState} from "./redux";

// @ts-ignore
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";

export const currency = <>&nbsp;💎</>;


function App() {
  const dispatch = useDispatch();
  const {user_id}: TState = useSelector((store: RootState) => store.user);
  const location = useLocation();

  // @ts-ignore
  const background = location.state && location.state.background;

  useEffect(() => {
      // Получаем сумму пользователя
      // @ts-ignore
      dispatch(getUserMoney(getCurrentUserId()));

      // Получаем user_id и диспачим в стор
      dispatch({
          type: SET_USER_ID,
          payload: getCurrentUserId(),
      });

      // Получаем историю ставок пользователя
      // @ts-ignore
      dispatch(fetchAllBetsByUserId(user_id));

      // Получаем реферальную ссылку на сайт
      // @ts-ignore
      dispatch(fetchGetLinkGoToWebsite(getCurrentUserId()));
      // @ts-ignore
      window.Telegram?.WebApp?.expand();
  }, [])

  return (
    <div className="App">
        <Routes location={background || location}>
          <Route path={'/bids'} element={<Bids />}></Route>
          <Route path={'/'} element={<Main />}></Route>
        </Routes>
    </div>
  );
}

export default App;
